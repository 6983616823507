<template>
  <div class="updatePassword" :style="'height: ' + windowHeight + 'px;'">
    <rxNavBar title="员工更多" androidOrIOSFlag="true" style="background-color: red" backColor="true"></rxNavBar>

    <div class="img_group">
     <img :src="headImg" class="oneImg">
     <div class="setPsd">员工更多</div>
    </div>
    
    <div class="appointmentDetail" :style="'max-height:' + (Number(windowHeight)-300) + 'px'">
      <div class="relevvant-detail relevvant-detail-border"  @click="clickInfo('ecommendation')" v-if="checkAuthList(ecommendationAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg17" class="detail-img"></div>
        <span class="houseRelevantText">员工内部推荐</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="checkToMap" v-if="checkAuthList(authButtonsList,'findByMap')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg9" class="detail-img"></div>
        <span class="houseRelevantText">地图找房</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="skipRenterContract" v-if="checkAuthList(authButtonsList,'portApproval')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg2" class="detail-img"></div>
        <span class="houseRelevantText">端口审批</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="alertPayCard" v-if="checkAuthList(authButtonsList,'editBankCard')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg3" class="detail-img"></div>
        <span class="houseRelevantText">修改工资卡</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="houseList" v-if="checkAuthList(authButtonsList,'houseList')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg4" class="detail-img"></div>
        <span class="houseRelevantText">房源列表</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="skipContractChange" v-if="checkAuthList(authButtonsList,'LeaseModification')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg8" class="detail-img"></div>
        <span class="houseRelevantText">租约变更</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="salaryApplication" v-if="checkAuthList(authButtonsList,'salaryApproval')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg5" class="detail-img"></div>
        <span class="houseRelevantText">工资审批</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="goodsChangeApplication" v-if="checkAuthList(authButtonsList,'prepareAllocationApproval')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg10" class="detail-img"></div>
        <span class="houseRelevantText">物品变更审批</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="addressBook" v-if="checkAuthList(authButtonsList,'mailList')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg6" class="detail-img"></div>
        <span class="houseRelevantText">通讯录</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="checkTomore" v-if="checkAuthList(authButtonsList,'helpCenter')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg7" class="detail-img"></div>
        <span class="houseRelevantText">帮助中心</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="probationAssignment" v-if="checkAuthList(authButtonsList,'probationAssignment')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg11" class="detail-img"></div>
        <span class="houseRelevantText">试岗任务</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="probationPlanApproval" v-if="checkAuthList(probationPlanApprovalAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg12" class="detail-img"></div>
        <span class="houseRelevantText">试岗任务审批</span>
        <van-tag v-if="paramData.mainTrialPlanRecord > 0" round type="danger" style="margin-left:10px">{{paramData.mainTrialPlanRecord > 99 ? '99+' : paramData.mainTrialPlanRecord}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="staffEntry" v-if="checkAuthList(staffEntryAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg14" class="detail-img"></div>
        <span class="houseRelevantText">员工入职审批</span>
        <van-tag v-if="paramData.employmentApprovalCount > 0" round type="danger" style="margin-left:10px">{{paramData.employmentApprovalCount > 99 ? '99+' : paramData.employmentApprovalCount}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="staffDepart" v-if="checkAuthList(staffDepartAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg15" class="detail-img"></div>
        <span class="houseRelevantText">员工离职审批</span>
        <van-tag v-if="paramData.employmentApprovalCount2 > 0" round type="danger" style="margin-left:10px">{{paramData.employmentApprovalCount2 > 99 ? '99+' : paramData.employmentApprovalCount2}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="personalResume">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg13" class="detail-img"></div>
        <span class="houseRelevantText">员工档案</span>
      </div>

      <div class="relevvant-detail relevvant-detail-border"  @click="clickInfo('jobTransfer')" v-if="checkAuthList(jobTransferAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg16" class="detail-img"></div>
        <span class="houseRelevantText">岗位调转</span>
      </div>

      

      <div class="relevvant-detail relevvant-detail-border"  @click="clickInfo('jobApplication')" v-if="checkAuthList(jobApplicationAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg18" class="detail-img"></div>
        <span class="houseRelevantText">新人试岗申请</span>
        <van-tag v-if="paramData.myInterpolateApproval > 0" round type="danger" style="margin-left:10px">{{paramData.myInterpolateApproval > 99 ? '99+' : paramData.myInterpolateApproval}}</van-tag>
      </div>

       <div class="relevvant-detail relevvant-detail-border"  @click="clickInfo('workOrrest')" v-if="checkAuthList(workOrrestAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="newStaffImg19" class="detail-img"></div>
        <span class="houseRelevantText">加班或调休</span>
        <van-tag v-if="paramData.workApproval > 0" round type="danger" style="margin-left:10px">{{paramData.workApproval > 99 ? '99+' : paramData.workApproval}}</van-tag>
      </div>
<!--      <div class="relevvant-detail relevvant-detail-border" @click="probationAssignment" v-if="checkAuthList(authButtonsList,'probationAssignment')">-->
<!--        <div style="height:1.2rem;float: left"><img :src="newStaffImg11" class="detail-img"></div>-->
<!--        <span class="houseRelevantText">试岗任务审批</span>-->
<!--      </div>-->

      <!--<div class="relevvant-detail relevvant-detail-border" @click="businessManual">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">业务类手册</span>
      </div>-->
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  NumberKeyboard,
  Toast,
  Calendar,
  DatetimePicker,
  Button,
  Popup, Tag
} from 'vant'
import {
  checkAndroid, checkIOS, getStaffId,
  globaluserId,
  money, openInWebview,
  responseUtil
} from "../../../libs/rongxunUtil";
import {
  getStaffFuntionModelList, otherApprovalCount, queryMyPageInit
} from "../../../getData/getData";
import { checkAuthList } from "../../../libs/rongxunUtil";

//调用android关闭页面方法*******begin*********
function backToAndroid(){
  window.himi.closeWindow();
}
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
export default {
  name: 'houseRelevant',
  components: {
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
    [DatetimePicker.name]: DatetimePicker,
    [Tag.name]: Tag,
    rxNavBar,
  },
  data() {
    return {
      userName:'',
      number:'617828',
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
      headImg: require("../../../assets/images/head-quotation-white.png"),
      houseRelevantRenterImg: require("../../../assets/images/house-relevant-renter.png"),  // 租客

      newStaffImg:require("../../../assets/images/newImgs/gongzi_1.png"),
      newStaffImg1:require("../../../assets/images/newImgs/yeji_1.png"),
      newStaffImg2:require("../../../assets/images/newImgs/duankou_1.png"),
      newStaffImg3:require("../../../assets/images/newImgs/gongzika_1.png"),
      newStaffImg4:require("../../../assets/images/newImgs/fanyuan_11.png"),
      newStaffImg5:require("../../../assets/images/newImgs/gongzi_6.png"),
      newStaffImg6:require("../../../assets/images/newImgs/tonxunlu_1.png"),
      newStaffImg7:require("../../../assets/images/newImgs/bangzhu_1.png"),
      newStaffImg9:require("../../../assets/images/newImgs/mapHourse.png"),
      newStaffImg10:require("../../../assets/images/newImgs/goodsChange.png"),
      newStaffImg8:require("../../../assets/images/newImgs/contractChange.png"),
      newStaffImg11:require("../../../assets/images/newImgs/probationAssignment.png"),
      newStaffImg12:require("../../../assets/images/probationPlanApproval.png"),
      newStaffImg13:require("../../../assets/images/newImgs/personalResume.png"),
      newStaffImg14:require("../../../assets/images/staffEntry.png"),
      newStaffImg15:require("../../../assets/images/staffDepart.png"),
      newStaffImg16:require("../../../assets/images/transfers.png"),
      newStaffImg17:require("../../../assets/images/ecommendation.png"),
      newStaffImg18:require("../../../assets/images/jobApplication.png"),
      newStaffImg19:require("../../../assets/images/workOrrest.png"),
      paramData:{},
      authButtonsList:[],
      probationPlanApprovalAuthButtonsList:[],
      staffEntryAuthButtonsList:[],
      staffDepartAuthButtonsList:[],
      jobTransferAuthButtonsList: [],
      ecommendationAuthButtonsList: [],
      jobApplicationAuthButtonsList: [],
      workOrrestAuthButtonsList: [],

    }
  },
  mounted() {
    this.getStaffFuntionModelList()
    this.queryMyPageInit()
    this.getOtherApprovalCount()
  },
  methods: {
    clickInfo:function (path) {
                this.$router.push({
                    name:path,
                    query:{
                        
                    }
                })
            },
    //跳转租客合同
    skipRenterContract(){
      this.$router.push({
        name: 'portApproveList',
        query: {
          fromType:'0',
          type: 1,
          room_id:this.roomId,
          setHouse_id:this.setHouse_id,
          currentLabel: '0',  // 全部
        }
      })
    },
    checkTomore() {
      // window.webkit.messageHandlers.openWebPage.postMessage({url:"helpCenterMenus"});
      this.$router.push({
            name: 'helpCenterMenus',
            query: {

            }
        })
    },
    //修改工资卡
    alertPayCard(){
      this.$router.push({
        name: 'staffPayCard',
        query: {

        }
      })
    },
    //房源列表
    houseList(){
      this.$router.push({
        name: 'houseList',
        query: {

        }
      })
    },
    checkToMap() {
      this.$router.push({
            name: 'baiduMap',
            query: {

            }
        })
    },
    //工资审批
    salaryApplication(){
      this.$router.push({
        name: 'salaryApproval',
        query: {

        }
      })
    },
    //物品变更审批
    goodsChangeApplication(){
      this.$router.push({
        name: 'goodsChangeApproval',
        query: {

        }
      })
    },
    // 租约变更
    skipContractChange(){
      // window.webkit.messageHandlers.openWebPage.postMessage({url:"contractChange"});
      this.$router.push({
        name: 'contractChange',
        query: {

        }
      })
    },
    //通讯录
    addressBook(){
      this.$router.push({
        name: 'addressBook',
        query: {

        }
      })
    },
    //业务类手册
    businessManual(){
      this.$router.push({
        name: 'businessManual',
        query: {

        }
      })
    },
    //试岗任务
    probationAssignment(){
      this.$router.push({
        name: 'probationAssignment',
        query: {

        }
      })
    },
    probationPlanApproval(){
      this.$router.push({
        name: 'probationPlanApproval',
        query: {
          type:1,
        }
      })
    },
    staffEntry(){
      this.$router.push({
        name: 'staffEntry',
        query: {
          type:1,
        }
      })
    },
    staffDepart(){
      this.$router.push({
        name: 'staffDepart',
        query: {
          type:1,
        }
      })
    },
    personalResume(){
      this.$router.push({
        name: 'personalResume',
        query: {

        }
      })
    },
    leftReturn() {
      if(openInWebview()){
        //APP传值*******begin*********
        if(checkAndroid()){
          backToAndroid();
        }else if(checkIOS()){
          window.webkit.messageHandlers.closeWindow.postMessage({});
        }
        //APP传值*******end*********
      }else{
        this.$router.go(-1)
      }
    },
    //权限方法
    getStaffFuntionModelList () {
        var that = this
        let data = {}
        data.staff_id = getStaffId()
        data.menuName = 'staffMore_index'
        getStaffFuntionModelList(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
                that.authButtonsList = response.data.data.data
            })
        })
        let data2 = {}
        data2.staff_id = getStaffId()
        data2.menuName = 'probationPlanApproval'
        getStaffFuntionModelList(data2).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.probationPlanApprovalAuthButtonsList = response.data.data.data
          })
        })
      let initData = {}
      initData.staff_id = getStaffId()
      initData.menuName = 'staffEntry'
      getStaffFuntionModelList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.staffEntryAuthButtonsList = response.data.data.data
        })
      })
      let queryData = {}
      queryData.staff_id = getStaffId()
      queryData.menuName = 'staffDepart'
      getStaffFuntionModelList(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.staffDepartAuthButtonsList = response.data.data.data
        })
      })
      let paramsAata = {
          staff_id: getStaffId(),
          menuName: "jobTransfer"
        }
        getStaffFuntionModelList(paramsAata).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.jobTransferAuthButtonsList = response.data.data.data
          })
        })
        let paramsEcomm = {
          staff_id: getStaffId(),
          menuName: "ecommendation"
        }
        getStaffFuntionModelList(paramsEcomm).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.ecommendationAuthButtonsList = response.data.data.data
          })
        })
        let paramsJob = {
          staff_id: getStaffId(),
          menuName: "newbieProbationApproval_index"
        }
        getStaffFuntionModelList(paramsJob).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.jobApplicationAuthButtonsList = response.data.data.data
          })
        })
        let paramsWorkOrrest = {
          staff_id: getStaffId(),
          menuName: "workOrRest_index"
        }
        getStaffFuntionModelList(paramsWorkOrrest).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.workOrrestAuthButtonsList = response.data.data.data
          })
        })
    },
    queryMyPageInit () {
        var that = this
        let data = {}
        data.user_id = globaluserId()
      queryMyPageInit(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              console.log(response.data.data.userName)
              that.userName = response.data.data.userName
            })
        })
    },
    checkAuthList,
    getOtherApprovalCount() {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      otherApprovalCount(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.paramData = response.data.data
          console.log(that.paramData,'that.paramData===')

        })
      })
    },
  }
}
</script>

<style scoped>
/*保存按钮不可点击样式*/
.saveButton_Disable{
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}

  .changeStatusStyle {
    height: 2rem;
  }
  .confirmCancelOrdersText {
    margin-left: 35px;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: bold
  }

  .cancelOrdersImg {
    float: left;
    margin: 20px 5px 0 15px;
    width: 15px;
    height: 15px;
  }
  .cnacelOrdersText {
    margin-top: 18px;
    font-size: 15px;
    font-weight: bold
  }

.relevvant-detail {
  width: 100%;
  height: 1.2rem;

  font-size: 15px;
  line-height: 1.2rem;
}
.relevvant-detail-border {
  border-bottom: #EFEFEF 1px solid;
  border-radius: 8px;
}


.detail-img {
  height:0.4rem;
  margin-left: 20px;
  padding-top: 0.42rem;
}

.houseRelevantText {
  margin-left: 10px;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
}

.updatePassword {
  background: linear-gradient(to bottom, #FFC274 0px, #FF5D3B 100%) repeat scroll 0% 0%;
  height: 18rem;
}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin-left:10px;
  margin-right:5px;
}
.content-none {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #c7c7c7;
}
.content-have {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #ff5d3b;
}
.roomMesage{
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5px;
}
.areaDiv{
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 12px;

}
.area {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.devide {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin: 0px 7px;
  font-family: PingFangSC-Regular;

}

.roomPhoto {
  width: 151.2px;
  height: 100.08px;
  /*padding: 5px;*/
  margin: 5px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.roomPhotoImage{
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.submitAppointment {
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 210px 15px 20px 15px;
  background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: white;
  line-height: 45px;
}

.inputDate {
  width: 190px;
  height: 20px;
  /*margin-left: 110px;*/
  outline-style: none;
  border: 0px;
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Regular;
  color: rgba(255, 93, 59, 1);
}

.devide2 {
  width: 1px;
  height: 14px;
  color: rgba(239, 239, 239, 1);
}

.timeText {
  height: 21px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Semibold;
}




.timePick {
  width: 18.56px;
  height: 19.24px;
  /*margin-left: 15px;*/
}

.timeDiv {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.spanMonth {
  color: rgba(255, 93, 59, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.money {
  width: 70px;
  height: 24px;
  margin-top: 7px;
  color: rgba(255, 93, 59, 1);
  font-size: 16px;
  font-family: Oswald-Regular;

}

.address {
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  font-family: PingFangSC-Regular;

}
.img_group {
  height: 75px;
  margin: 29px 0px;
}

.setPsd {
  width: 112px;
  height: 40px;
  color: white;
  font-size: 28px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  position: relative;
  font-weight: bold;
  left: 24px;
  bottom: 40px;
  white-space: nowrap;
}

.oneImg {
  width: 58px;
  height: 66px;
}

.appointmentDetail {
  width: 92%;
  margin: 0 auto;
  height: auto;
  overflow: scroll;
  /*display: flex;*/
  /*align-items: flex-start;*/
  /*justify-content: space-between;*/
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);

}
.selectTime{
  width: 10rem;
  height: 3rem;
}


</style>
